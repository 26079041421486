<template>
  <b-card
    v-if="products"
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper"
      >
        <b-table
          :per-page="perPage"
          :current-page="currentPage"
          :items="products"
          responsive
          :fields="fields"
          class="mb-0"
          :busy="isBusy"
        >
          <!-- name -->
          <template #cell(name)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                rounded
                size="32"
                variant="light-company"
              >
                <b-img
                  src="@/assets/images/icons/alerte.png"
                  alt="avatar img"
                /></b-avatar>
              <div class="ml-1">
                <div class="font-weight-bolder">
                  {{ data.item.name }}
                </div>
                <div class="font-small-2">
                  {{ data.item.reference }}
                </div>
              </div>
            </div>
          </template>

          <!-- quantity -->
          <template #cell(quantity)="data">
            <div class="d-flex align-items-center">
              <span class="font-weight-bolder mr-1">{{
                data.item.quantity
              }}</span>
              <feather-icon
                icon="TrendingDownIcon"
                class="text-danger"
              />
            </div>
          </template>
          <!--loading-->
          <template #table-busy>
            <div class="text-center text-warning my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="products.length"
          :per-page="perPage"
          align="end"
          size="sm"
          class="my-1"
        />
      </b-col>

      <b-col
        v-if="revenuToday.revenue"
        md="4"
        class="budget-wrapper"
      >
        <h2 class="mb-25">
          {{ revenuToday.revenue }} TND
        </h2>
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Nombre de vente :</span>
          <span>{{ revenuToday.number }}</span>
        </div>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="series"
        />

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Commande
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BSpinner,
  BPagination,
  BButton,
  BCol,
  BRow,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BSpinner,
    BPagination,
    BButton,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      series: [
        {
          data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
        },
        {
          data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
        },
      ],

      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
      revenuToday: {},
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      products: [],
      isBusy: false,
      fields: [
        { key: 'name', label: 'produit' },
        { key: 'quantity', label: 'stock ' },
        { key: 'minimum_quantity', label: 'stock minimale ' },
      ],
    }
  },

  created() {
    this.getProducts()
    this.getRevenue()
  },
  methods: {
    async getProducts() {
      this.isBusy = true
      const { data } = await axiosIns.get('/products/alert/')
      this.products = data
      this.isBusy = false
    },
    async getRevenue() {
      const { data } = await axiosIns.get('/orders/revenue/')
      this.revenuToday.number = data.number
      this.revenuToday.revenue = data.revenue
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
